import { Box, Container, Grid, Paper, styled } from "@mui/material";
import TitleSections from "../../../shared/title-sections/title-sections";

import '../../../assets/css/home.scss';
import logo_home from '../../../assets/images/logo_home.png';

const WelcomeHome = () => {
    return(
        <Container>
            <Box sx={{ height: 'auto' }}>
                <div className='container-main' style={{gap: '100px'}}>
                    <TitleSections title='Welcome to Rolando VC' color='#FFFFFF' />
                    <Grid container spacing={2} className="container-logo-text">
                        <Grid item xs={12} md={6} className="container-logo-home">
                            <img src={logo_home} alt="" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className="container-text-home">
                                <span><strong>Rolando</strong> first came to Cabo on vacation, and ended up falling in love with it’s natural beauty! He decided to stay, make it his home, and start a family.</span>
                                <span>He is now the proud father of 2 boys! He started a business in the travel and tourism industry as a tour guide and transportation service, to support his family.</span>
                                <span>He has been very successful, and continues to operate a thriving business! Customers keep coming back to him due to his outgoing personality, and ability to make visitors feel at home.</span>
                                <span>He will go out of his way to help make your stay in Cabo perfect!</span>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Box>
        </Container>
    );
};

export default WelcomeHome;