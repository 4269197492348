import { Box } from '@mui/material';
import Container from '@mui/material/Container';
import '../../../assets/css/home.scss';
import CardsTours from '../../../shared/cards-tours/cards-tours';

const ToursHome = () => {
  return (
    <Container fixed >
        <Box sx={{ height: 'auto' }} >
            <div className='container-main'>
                <CardsTours />
            </div>
        </Box>
    </Container>
  );
};

export default ToursHome;