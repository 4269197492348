import { Box, Button, Container, Grid, Stack, TextField } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';

import '../../assets/css/contactus.scss';
import { InputPhone } from "../../components/input-phone/input-phone";
import React from "react";
import { InputEmail } from "../../components/input-email/input-email";
import { InputText } from "../../components/input-text/input-text";
import { sendMail } from "../../api/services/api.services";

const ContactMeForm = (props: any) => {

    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [isEmailValid, setIsEmailValid] = React.useState(false);
    const [buttonDisabled, setButtonDisabled] = React.useState(true);
    const [isSending, setIsSending] = React.useState(false);

    const onChangeEmail = (value: any, isEmailValid: boolean) => {
        setEmail(value);
        setIsEmailValid(isEmailValid);
    }

    const onHandleSend = async () => {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("phone", phone);
        formData.append("message", message);
        formData.append("service", props.service)

        setButtonDisabled(true);
        setIsSending(true);

        const result = await sendMail(formData);
        
        setIsSending(false);
        setButtonDisabled(false);

        if(result.codigo === 200) {
            initForm();
        }
    };

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    React.useEffect(() => {
        if(isEmailValid && name !== '' && phone !== '' && message !== ''){
            setButtonDisabled(false);
        }else{
            setButtonDisabled(true);
        }
    }, [isEmailValid, name, phone, message]);

    function initForm() {
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
        setIsEmailValid(false);
        setIsSending(false);
        setButtonDisabled(true);
    }

    return(<div className='container-contact-me'>
            <Container fixed >
                <Box sx={{ height: 'auto' }} >
                    <h3>Contact me</h3>
                    <div className='container-main-me'>
                        <Grid container spacing={3}>                            
                            <Grid item xs={12} md={4}>
                                <InputText 
                                    id="name" 
                                    label="Name *"
                                    value={name}
                                    onChange={(value: string) => setName(value)}
                                    placeholder="Enter your Name"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <InputEmail 
                                    id="email" 
                                    label="Email *" 
                                    value={email} 
                                    onChange={(value: any, isEmailValid: boolean) => onChangeEmail(value, isEmailValid)} 
                                    placeholder="Enter your Email"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <InputPhone 
                                    id="phone" 
                                    label="Phone *" 
                                    value={phone} 
                                    onChange={(value: any) => setPhone(value)} 
                                    placeholder="Enter your Phone" />
                            </Grid>
                            <Grid item xs={12}>
                            <TextField
                                id="message"
                                label="Message *"
                                multiline
                                fullWidth
                                variant="standard" 
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={1} justifyContent={"center"} >
                                    <Button 
                                        variant="contained" 
                                        endIcon={<SendIcon />} 
                                        className="button-send" 
                                        disabled={buttonDisabled} 
                                        onClick={onHandleSend}
                                    >
                                        {
                                            isSending ? 'Sending...' : 'Send'
                                        }
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </div>
                </Box>
            </Container>
        </div>
    )
    
};

export default ContactMeForm;