import { FloatingWhatsApp } from 'react-floating-whatsapp'

import whatsapp from '../../assets/images/WhatsApp.svg';

const WhatsApp = () => {
    return (
        <FloatingWhatsApp
            phoneNumber="526241551025"
            accountName="Rolando VC - Los Cabos"
            avatar={whatsapp}
            chatMessage="Hello, how can we help you?"
            darkMode={false}
        />
    )
}

export default WhatsApp;