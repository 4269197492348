import { TextField, TextFieldVariants } from "@mui/material";
import { InputTextProps } from "../../interfaces";


export const InputText: React.FC<InputTextProps> = ({ id, label, value, onChange, placeholder, variant }) => {
    
    const textFieldVariant: TextFieldVariants = variant !== undefined ? variant : 'standard';

    return (
        <TextField 
            id={id} 
            label={label}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            autoComplete="new-password"
            fullWidth
            variant={textFieldVariant}
            size="small"
        />
    );
};