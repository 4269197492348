import todo_santos from '../assets/images/tours/todos_santos.jpg';
import art_walk from '../assets/images/tours/art_walk.jpg';
import cerritos from '../assets/images/tours/cerritos_beach.jpg';
import airport from '../assets/images/tours/airport_transportation.jpg';
import fishing from '../assets/images/tours/sport_fishing.jpg';

import todos_santos_1 from '../assets/images/tours/todos_santos/todos_santos_1.jpg';
import todos_santos_2 from '../assets/images/tours/todos_santos/todos_santos_2.jpg';
import todos_santos_3 from '../assets/images/tours/todos_santos/todos_santos_3.jpg';
import todos_santos_4 from '../assets/images/tours/todos_santos/todos_santos_4.jpg';

import san_jose_1 from '../assets/images/tours/san_jose_cabo/san_jose_1.jpg';
import san_jose_2 from '../assets/images/tours/san_jose_cabo/san_jose_2.jpeg';
import san_jose_3 from '../assets/images/tours/san_jose_cabo/san_jose_3.jpg';
import san_jose_4 from '../assets/images/tours/san_jose_cabo/san_jose_4.webp';

import cerritos_1 from '../assets/images/tours/cerritos/cerritos_1.jpg';
import cerritos_2 from '../assets/images/tours/cerritos/cerritos_2.jpg';
import cerritos_3 from '../assets/images/tours/cerritos/cerritos_3.jpg';
import cerritos_4 from '../assets/images/tours/cerritos/cerritos_4.jpg';

const toursMock = [
    {
        id: 'todo-santos',
        title: 'Todo Santos Tour',
        image: todo_santos,
        duration: ['Duration: 6 hours', 'Tour Includes: Water & Beer', 'Free Time To Walk Around', 'Free Time For Lunch'],
        color: '#9428d6',
        description: 'At the pacific coast we can find this beautiful and charming town, on the way you can enjoy the beautiful view of the Pacific Ocean, at the town we will find souvenirs stores diferent kind of Art and a Delicious food with a lot of choices, en joy the flavor and the great atmosphere around this town.',
        gallery: [todos_santos_1, todos_santos_2, todos_santos_3, todos_santos_4]
    },
    {
        id: 'art-walk',
        title: 'Art Walk San Jose del Cabo',
        image: art_walk,
        duration: ['Duration: 5 hours','San Jose Plaza For Art Show Only (no other location includ)', 'We take you to the Art Walk and take you back to the hotel'],
        color: '#fdb40f',
        description: 'Every Thursday night from November to June, the Art District of San Jose del Cabo comes alive with art, wine, and music. The Art Walk is a popular event that draws tourists and locals alike to the galleries and shops of the historic downtown area. The Art Walk is a great way to experience the local art scene and get a feel for the culture of Los Cabos. The event is free and open to the public, and it is a great way to spend an evening in San Jose del Cabo.',
        gallery: [san_jose_1, san_jose_2, san_jose_3, san_jose_4]
    },
    {
        id: 'cerritos-beach',
        title: 'Cerritos Beach Day',
        image: cerritos,
        duration: ['Duration: 5 hours', 'Round Trip Transportation', 'Beach Umbrellas and Chairs', 'Water & Beer Included'],
        color: '#e0316f',
        description: 'Cerritos Beach is a popular spot for surfers and beachgoers in Los Cabos. The beach is located on the Pacific side of the Baja Peninsula, about an hour\'s drive from Cabo San Lucas. The beach is known for its great surf breaks and beautiful scenery. The beach is a great place to relax and enjoy the sun, sand, and surf. There are also several restaurants and bars on the beach where you can grab a bite to eat or a cold drink. Cerritos Beach is a great place to spend a day in Los Cabos.',
        gallery: [cerritos_1, cerritos_2, cerritos_3, cerritos_4]
    },
    {
        id: 'sport-fishing',
        title: 'Sport Fishing Charter',
        image: fishing,
        duration: [],
        color: '#6dc82b',
        description: 'Los Cabos is known as one of the best sport fishing destinations in the world. The waters off the coast of Cabo San Lucas are teeming with marlin, dorado, tuna, and other game fish. There are many charter companies in Cabo that offer fishing trips for anglers of all skill levels. Whether you are an experienced angler or a first-time fisherman, there is a fishing trip for you in Los Cabos. The fishing in Cabo is world-class, and it is a must-do activity for anyone visiting the area.',
        gallery: []
    },
    {
        id: 'airport-transportation',
        title: 'Airport Transportation',
        image: airport,
        duration: ['Private One Way Airport Pickup','Private Round Trip Airport Pickup/Drop'],
        color: '#4a47eb',
        description: 'Traveling can start off easy when you have your airport transportation pre-arranged. We offer private transportation to-and-from you hotel that includes our "meet-and-greet" service. All driver’s speak English and Spanish.',
        gallery: []
    },
];

export default toursMock;
