import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router-dom';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
  background: string;
}

interface CardProps {
  id: string;
  title: any;
  image: string;
  duration: string[];
  color: string;
  description: string;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, background, ...other } = props;
  return <IconButton {...other} style={{ color: background}} />;
})(({ theme, expand }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const RecipeReviewCard: React.FC<CardProps> = (props) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(false);
  const [background, setBackground] = React.useState(props.color);

  const handleExpandClick = (id: string) => {
    navigate(`/tours/${id}`);
  };

  const handleBookNow = () => {
  };

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader title={ props.title } style={{ color: '#FFF', backgroundColor: background }} />
      <CardMedia
        component="img"
        height="194"
        image={props.image}
        alt={props.image}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary" className='text-limited'>
          { props.description }
        </Typography>
      </CardContent>
      <CardActions disableSpacing style={{ borderBottom: '6px solid', borderBottomColor: background  }} className='box-shadow-cards'>
          {/* <button type="button" className="dt-sc-button too-small" style={{backgroundColor: background}} onClick={() => handleBookNow()}>Book Now</button> */}
          <ExpandMore
            expand={expanded}
            onClick={() => handleExpandClick(props.id)}
            aria-expanded={expanded}
            aria-label="show more"
            background={background}
          >
            <SendIcon />
          </ExpandMore>
      </CardActions>
    </Card>
  );
}

export default RecipeReviewCard;