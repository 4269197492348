import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import './slider.css';

import slider1 from '../../assets/images/slider/slider1.jpg';
import slider2 from '../../assets/images/slider/slider2.jpg';
import slider3 from '../../assets/images/slider/slider3.jpg';
import slider4 from '../../assets/images/slider/slider4.jpg';
import slider5 from '../../assets/images/slider/slider5.jpg';
import slider6 from '../../assets/images/slider/slider6.jpg';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const Slider = () => {
    return(
        <AutoplaySlider
            play={true}
            cancelOnInteraction={false} // should stop playing on user interaction
            bullets={false}
            interval={3000}
        >
            <div data-src={slider5} />
            <div data-src={slider1} />
            <div data-src={slider2} />
            <div data-src={slider3} />
            <div data-src={slider4} />
            <div data-src={slider6} />
        </AutoplaySlider>
    )
}

export default Slider;