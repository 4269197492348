import './App.css';
import ResponsiveAppBar from './shared/app-bar/app-bar';
import appBarMock from './mocks/app-bar';
import Home from './pages/home';

import { Routes, Route } from 'react-router-dom';
import Tours from './pages/tours';
import Contact from './pages/contact';
import Footer from './shared/footer/footer';

function App() {
  return (
    <>
        <ResponsiveAppBar {...appBarMock} />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/tours" element={<Tours />} />
                <Route path="/tours/:id" element={<Tours />} />
                <Route path="/contact" element={<Contact />} />
            </Routes>
        <Footer />
    </>
  );
}

export default App;
