import logo from '../assets/images/logo.png';

const appBarMock = {
    logo,
    name: 'ROLANDO VC',
    menu: [
        { path: '/tours', description: 'TOURS & SERVICES'},
        { path: '/contact', description: 'CONTACT ME'}
    ]
};

export default appBarMock;