import './title-sections.css';

interface TitleProps {
    title: string;
    color?: string;
    containerWidth?: string;
}

const TitleSections: React.FC<TitleProps> = ({ title, color, containerWidth }) => (
    <div className='container-title-sections' style={{ width: containerWidth ? containerWidth : '' }}>
        <span className="title-sections" style={{color: color ? color : '#3f3f3f'}}>{ title }</span>
        <div className='border-bottom'></div>
    </div>
);

export default TitleSections;