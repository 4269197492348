import TitleSections from "../title-sections/title-sections";
import RecipeReviewCard from '../../shared/card/card';
import mockData from '../../mocks/Tours';

const CardsTours = () => {
    return (
        <>
            <TitleSections title='Tours & Services'/>
            <div className='container-cards'>
                {
                mockData.map((item, i) => {
                    return <RecipeReviewCard key={i} {...item} />;
                })
                }
            </div>
        </>
    );
}

export default CardsTours;