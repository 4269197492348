export const sendMail = async (formData: any) =>{
    try {
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${baseURL}/page/sendmail-page`, {
            method: 'POST',
            body: formData,
        });
        
        const json = await response.json();

        return json;
    } catch (error) {
        console.error('Error sending email:', error);
    }
}