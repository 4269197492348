import california_28_ft from '../assets/images/boats/california_28_ft.jpg';
import bertram_35_ft from '../assets/images/boats/bertram_35_ft.jpg';

const boatsMock = [
    {
        id: 1,
        name: '28 FT California',
        equipment: [
            {
                type: 'Fishing Equipment', equipment: ['Up to 4 anglers', 'Professional fishing rods & reels', 'Ice chest & ice', 'Life vest', 'Insurance']
            },
            {
                type: 'Boat Equipment', equipment: ['Single cummins 250 HP engines', 'GPS', 'English spoken crew', 'VHF Radio', 'Private W.C.']
            },
        ],
        packages: [
            {
                upTo: '4', time: [{ hours: '5 Hours', price: '$1,010 USD' }, { hours: '8 Hours', price: '$1,130 USD' }]
            }
        ],
        image: california_28_ft,        
    },
    {
        id: 2,
        name: '35 FT Bertram',
        equipment: [
            {
                type: 'Fishing Equipment', equipment: ['Up to 7 anglers', 'Professional fishing rods & reels', 'Cooler', 'Ice chest & ice', 'Life vest', 'Insurance']
            },
            {
                type: 'Boat Equipment', equipment: ['Twin cat 375 HP engines', 'GPS', 'English spoken crew', 'Fish finder', 'VHF Radio', 'Private W.C.']
            },
        ],
        packages: [
            {
                upTo: '7', time: [{ hours: '5 Hours', price: '$1,606 USD' }, { hours: '8 Hours', price: '$1,785 USD' }]
            }
        ],
        image: bertram_35_ft,        
    },    
];

export default boatsMock;