import background from '../../assets/images/bg45.jpg';

import '../../assets/css/tours.scss';
import { Box, Card, CardMedia, Container, Grid, ImageList, ImageListItem, Typography } from '@mui/material';
import TitleSections from '../../shared/title-sections/title-sections';
import { useParams } from 'react-router-dom';
import mockData from '../../mocks/Tours';
import CardsTours from '../../shared/cards-tours/cards-tours';
import { ImagesGallery } from '../../shared/gallery';
import Icon from '@mui/material/Icon';

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import '../../shared/slider/slider.css';
import Calendar from '../../shared/calendar/calendar';
import ContactMeForm from '../../shared/contact-me/contact-me';
import { useEffect } from 'react';

import boatsMock from '../../mocks/boats';
import BoatCard from '../../shared/card-boat/card-boat';


const AutoplaySlider = withAutoplay(AwesomeSlider);

function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

const Tours = () => {
    const { id } = useParams();
    const title = 'Tours & Services';
    const tours = mockData.find((item, i) => item.id === id);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return(
        <div className='container-tours'>
            <div className='page-header page-header-small' style={{ backgroundImage: `url(${background})`}}>
                <div className="container-title">
                    <div className="content-center">
                        <h1 className='title'>{ tours?.title || title }</h1>
                    </div>
                </div>
            </div>
            <Container fixed >
                <Box sx={{ height: 'auto' }} >
                    <div className='container-information-tours'>
                        {
                            (tours) ?
                                <>
                                    <TitleSections title={ tours?.title! || title } />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <div>
                                                <p>{ tours?.description }</p>
                                                <h4>
                                                    We offer the following { tours?.title.toLowerCase() } services:
                                                </h4>
                                                {
                                                    tours?.duration?.map((item, i) => {
                                                        return(
                                                            <div key={i} className='container-offer-icon'><Icon sx={{color: '#1976d2'}}>check</Icon><Typography key={i} variant='body1'>{ item }</Typography></div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Card>
                                                <CardMedia
                                                    component="img"
                                                    height="405"
                                                    image={tours?.image}
                                                    alt={tours?.image}
                                                />
                                            </Card>
                                        </Grid>
                                    </Grid>
                                    <div className='container-gallery-tours'>
                                    {
                                        (tours?.gallery?.length > 0) 
                                        && 
                                            tours?.gallery?.map((item, i) => {
                                                return <ImagesGallery key={i} image={item} />
                                            })
                                    }
                                    </div>
                                    {
                                        (tours?.id === 'sport-fishing') 
                                        &&
                                            <Grid container spacing={2}>
                                                {
                                                    boatsMock.map((item, i) => {
                                                        return <Grid key={i} item xs={12} md={6}>
                                                            <BoatCard key={i} {...item} />
                                                        </Grid>
                                                    })
                                                }
                                            </Grid>
                                    }
                                    {/* <Calendar /> */}
                                    <ContactMeForm service={id}/>
                                </>
                            :
                            <CardsTours />
                        }
                    </div>
                </Box>
            </Container>
            <div style={{ height: '50px', width: '100%' }}></div>
        </div>
    )
}

export default Tours;