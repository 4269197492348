import { TextField, TextFieldVariants } from "@mui/material";
import React from "react";
import { IMaskInput } from "react-imask";
import { CustomProps, InputTextProps } from "../../interfaces";

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
    function TextMaskCustom(props, ref) {
      const { onChange, ...other } = props;
      return (
        <IMaskInput
          {...other}
          mask="(#00) 000-0000"
          definitions={{
            '#': /[1-9]/,
          }}
          inputRef={ref}
          onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
          overwrite
        />
      );
    },
);

export const InputPhone: React.FC<InputTextProps> = ({ id, label, value, onChange, placeholder, variant }) => {
    
    const textFieldVariant: TextFieldVariants = variant !== undefined ? variant : 'standard';

    return (
        <TextField 
            id={id} 
            label={label} 
            InputProps={{
                inputComponent: TextMaskCustom as any,
            }}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            autoComplete="new-password"
            fullWidth
            variant={textFieldVariant}
            size="small"
        />
    );
};