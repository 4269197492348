import Slider from "../../../shared/slider/slider";

const SliderHome = () => {
    return (
      <div>
        <Slider />      
      </div>
    );
  };
  
  export default SliderHome;