import { Box, Button, Container, Grid, Stack, TextField } from "@mui/material";
import { IMaskInput } from 'react-imask';

import logo from '../../assets/images/logo_home.png';
import SendIcon from '@mui/icons-material/Send';

import '../../assets/css/contactus.scss';
import TitleSections from "../../shared/title-sections/title-sections";
import React, { useEffect } from "react";
import { sendMail } from "../../api/services/api.services";
import { InputPhone } from "../../components/input-phone/input-phone";
import { InputText } from "../../components/input-text/input-text";
import { InputEmail } from "../../components/input-email/input-email";

const Contact = () => {
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [buttonDisabled, setButtonDisabled] = React.useState(true);
    const [isEmailValid, setIsEmailValid] = React.useState(false);
    const [isSending, setIsSending] = React.useState(false);

    const onChangeEmail = (value: any, isEmailValid: boolean) => {
        setEmail(value);
        setIsEmailValid(isEmailValid);
    }

    const onHandleSend = async () => {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("phone", phone);
        formData.append("message", message);

        setButtonDisabled(true);
        setIsSending(true);

        const result = await sendMail(formData);
        
        setIsSending(false);
        setButtonDisabled(false);

        if(result.codigo === 200) {
            initForm();
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if(isEmailValid && name !== '' && phone !== '' && message !== ''){
            setButtonDisabled(false);
        }else{
            setButtonDisabled(true);
        }
    }, [isEmailValid, name, phone, message]);

    function initForm() {
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
        setButtonDisabled(true);
        setIsEmailValid(false);
        setIsSending(false);
    }

    return(
        <div className='container-contact'>
            <Container fixed >
                <Box sx={{ height: 'auto' }} >
                    <div className='container-main'>
                        <TitleSections title={ 'Contact me' } />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} className="container-logo">
                                <img src={logo} alt="" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="container-form">
                                    <InputText 
                                        id="name" 
                                        label="Name *"
                                        value={name}
                                        onChange={(value: string) => setName(value)}
                                        placeholder="Enter your Name"
                                    />
                                    <InputEmail 
                                        id="email" 
                                        label="Email *" 
                                        value={email} 
                                        onChange={(value: any, isEmailValid: boolean) => onChangeEmail(value, isEmailValid)} 
                                        placeholder="Enter your Email"
                                    />
                                    <InputPhone 
                                        id="phone" 
                                        label="Phone *" 
                                        value={phone} 
                                        onChange={(value: any) => setPhone(value)} 
                                        placeholder="Enter your Phone" />
                                    <TextField
                                        id="message"
                                        label="Message *"
                                        multiline
                                        rows={2}
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        variant="standard"
                                    />
                                    <Stack direction="row" spacing={1} justifyContent={"center"}>
                                        <Button 
                                            variant="contained" 
                                            endIcon={<SendIcon />} 
                                            className="button-send" 
                                            disabled={buttonDisabled} 
                                            onClick={onHandleSend}
                                        >
                                            {
                                                isSending ? 'Sending...' : 'Send'
                                            
                                            }
                                        </Button>
                                    </Stack>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Box>
            </Container>
        </div>
    )
}

export default Contact;