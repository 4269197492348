import { TextField, TextFieldVariants } from "@mui/material";
import { InputTextProps } from "../../interfaces";
import React from "react";

export const InputEmail: React.FC<InputTextProps> = ({ id, label, value, onChange, placeholder, variant }) => {
        
    const textFieldVariant: TextFieldVariants = variant !== undefined ? variant : 'standard';
    const [emailError, setEmailError] = React.useState('');

    const validateEmail = (value: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        if(!emailRegex.test(value)){
            setEmailError('Please enter a valid email address');
            return false;
        }else{
            setEmailError('');
            return true;
        }
    };

    const handleEmailChange = (event: any) => {
        const { value } = event.target;
        onChange(value, validateEmail(value));
    };

    return (
        <div className="container-email">
            <TextField 
                id={id} 
                label={label}
                variant={textFieldVariant} 
                placeholder={placeholder}
                value={value}
                onChange={handleEmailChange}
                autoComplete="new-password"
                size="small"
            />
            {
                emailError !== "" && <small style={{color:'red'}}>{ emailError }</small>
            }
        </div>
    );
};