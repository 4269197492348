import SliderHome from './slider';
import Tours from './tours';
import WelcomeHome from './welcome';

import arch from '../../assets/images/arch.jpg';
import { useEffect } from 'react';

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    
    return (
        <>
            <SliderHome />
            <div className='container-home-welcome background-welcome-text' style={{ backgroundImage: `url(${arch})`}}>
                <WelcomeHome />
            </div>
            <div className='container-home-tours'>
                <Tours />
            </div>
        </>
    );
};

export default Home;