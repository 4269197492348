import { Card, CardMedia, Container } from "@mui/material";
import './gallery.css';

interface Image {
    image: any
}

export const ImagesGallery: React.FC<Image> = (data) => {
    return(
        <>
            <Container fixed>
                <Card sx={{ maxWidth: 345}}>
                    <CardMedia
                        component="img"
                        height="194"
                        image={data.image}
                        alt={data.image}
                    />
                </Card>
            </Container>
        </>
    )    
}