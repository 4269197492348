import { Card, CardActions, CardContent, CardHeader, CardMedia, Grid, IconButton, IconButtonProps, styled } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import ExpandMore from "@mui/icons-material/ExpandMore";
import { ExpandLess } from "@mui/icons-material";
import React from "react";

import './card-boat.scss';

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
    background: string;
  }
  
//   

const ExpandMoreComponent = styled((props: ExpandMoreProps) => {
    const { expand, background, ...other } = props;
    return <IconButton {...other} style={{ color: background}} />;
  })(({ theme, expand }) => ({
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

const AllInclusive = ['Fishing Licenses','Fee access','1 Box lunch p/p (Burritos, Turkey, Sandwich)','6 Drinks p/p (Bottle water, Soda Pop, Mexican beer)','Live bait (subject to availability)','Round trip transportation Hotel-Marina-Hotel'];

const BoatCard = (props: any) => {
    
    const [expanded, setExpanded] = React.useState(false);
    const [background, setBackground] = React.useState('#035f8e');

    const handleExpandClick = (id: string) => {
        setExpanded(!expanded);
    };

    return(
        <Card className="titles">
            <CardHeader title={ props.name } style={{ color: '#FFF', backgroundColor: background }} />
            <CardMedia
                component="img"
                height="350"
                image={props.image}
                alt={props.image}
                onClick={() => handleExpandClick(props.id)}
                style={{ cursor: 'pointer' }}
            />
            {
                (expanded) 
                && 
                    <CardContent className="card-content">
                        <Grid container spacing={1}>
                            {
                                <Grid item xs={12} md={12}>
                                    <div className="container-packages">
                                        <div className="packages">
                                            <h3>Packages</h3>
                                            <span>CASH PAYMENT APPLY 15% OFF</span>
                                            <strong>* ALL TAXES INCLUDED *</strong>
                                        </div>
                                        <div className="prices">
                                            {
                                                props.packages.map((item: any, i: number) => {
                                                    return <div key={i} className="package">
                                                        <h3>
                                                            Up to { item.upTo } Anglers
                                                        </h3>
                                                        <ul>
                                                            {
                                                                item.time.map((element: any, j: number) => {
                                                                    return <li key={j}>
                                                                        <label>{ element.hours }</label>&nbsp;&nbsp;
                                                                        <span>{ element.price }</span>
                                                                    </li>
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </Grid>
                            }
                        </Grid>
                        <Grid container spacing={2}>
                            {
                                props.equipment.map((item: any, i: number) => {
                                    return <Grid key={i} item xs={6} md={6}>
                                        <h3>
                                            { item.type }
                                        </h3>
                                        <ul>
                                            {
                                                item.equipment.map((element: string, j: number) => {
                                                    return <li key={j}><label style={{fontSize: '13px'}}>{ element }</label></li>
                                                })
                                            }
                                        </ul>
                                    </Grid>
                                })
                            }
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={6}>
                                <h3>
                                    All Inclusive
                                </h3>
                                <ul>
                                    {
                                        AllInclusive.map((element: string, j: number) => {
                                            return <li key={j}><label style={{fontSize: '13px'}}>{ element }</label></li>
                                        })
                                    }
                                </ul>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <div style={{width: '100%', textAlign: 'center'}}>
                                    <h3>WHAT'S NOT INCLUDED<br />GRATUITIES FOR THE CREW</h3>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
            }
            
            <CardActions disableSpacing style={{ borderBottom: '6px solid', borderBottomColor: background  }} className='box-shadow-cards'>
                {/* <button type="button" className="dt-sc-button too-small" style={{backgroundColor: '#6dc82b'}}>Contact me</button> */}
                <ExpandMoreComponent
                    expand={expanded}
                    onClick={() => handleExpandClick(props.id)}
                    aria-expanded={expanded}
                    aria-label="show more"
                    background={background}
                >
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                </ExpandMoreComponent>
            </CardActions>
        </Card>
    );
};

export default BoatCard;