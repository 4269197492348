import { Box, Grid } from "@mui/material";
import { Link } from "react-router-dom";

import mockData from '../../mocks/Tours';

import './footer.scss';
import WhatsApp from "../whats-app/whats-app";

const Footer = () => {

    return (
        <>
            <WhatsApp />
            <footer>
                <Box sx={{ 
                    flexGrow: 1, 
                    minHeight: '300px',
                    backgroundColor: '#333',
                    color: '#FFF',
                    }}  
                    style={{ padding: '30px', display: 'flex', justifyContent: 'center' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} className="container-our-services">
                                <h3>Contact us</h3>
                                <div><strong>In Los Cabos</strong></div>
                                <div><strong>Phone:</strong> +152 (624) 155-1025</div>
                                <div><strong>Email:</strong> rolandovc963@gmail.com</div>
                            </Grid>
                            <Grid item xs={12} md={4} className="container-our-services">
                                <h3>Our services</h3>
                                {
                                    mockData.map((item, i) => {
                                        return(
                                            <Link to={`/tours/${item.id}`} key={i} className='links'>- { item.title }</Link>
                                        )
                                    })
                                }
                            </Grid>
                            <Grid item xs={12} md={4} className="container-our-services">
                                <h3>Related Pages</h3>
                                <a href='https://nobaddaystransport.com/' target="_new" className='links'><span>No bad days transport - Los Cabos</span></a>
                                {/* <a href=''><span>Fun around Los Cabos</span></a> */}
                                {/* <a href='https://brotherscharters.com/' target="_new" className='links'><span>Brothers Charters - Los Cabos</span></a> */}
                            </Grid>
                        </Grid>
                </Box>
            </footer>
        </>
    );
}

export default Footer;